import { LoadedIndicator } from "./LoadedIndicator";
class Channel {
    constructor() {
        Object.defineProperty(this, "file", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "currentChunk", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        Object.defineProperty(this, "chunks", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        Object.defineProperty(this, "timestamp", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        Object.defineProperty(this, "changeIndicators", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "channel", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "receivedFileBuffer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "indicators", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "startDownload", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: true
        });
        Object.defineProperty(this, "firstChunkforOnMessageListener", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 2
        });
        Object.defineProperty(this, "chunkSize", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 12000
        });
        Object.defineProperty(this, "readFileInChunks", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const chunkSize = 12000;
                const reader = new FileReader();
                reader.onload = (event) => {
                    if (event.target?.result) {
                        const chunk = reader.result;
                        const message = {
                            chunk_number: this.currentChunk,
                            chunk: Array.from(new Uint8Array(chunk)),
                            file_id: this.id,
                            file_size: this.file.size,
                            file_name: this.file.name,
                            lifetime: 60 * 60 * 24,
                            timestamp: this.timestamp
                        };
                        const encodedMessage = btoa(JSON.stringify(message));
                        this.channel?.send(encodedMessage);
                        this.currentChunk++;
                        if (this.channel.bufferedAmount < this.channel.bufferedAmountLowThreshold) {
                            this.readFileInChunks();
                        }
                        if (this.currentChunk === 1) {
                            this.readFileInChunks();
                        }
                    }
                };
                reader.onerror = function (err) {
                    new Error(`error with send file ${err}`);
                };
                this.loadNext(reader, chunkSize);
            }
        });
        Object.defineProperty(this, "loadNext", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (reader, chunkSize) => {
                const start = this.currentChunk * chunkSize;
                if (this.file) {
                    const blobSlice = new Blob([this.file]);
                    const chunk = blobSlice.slice(start, start + chunkSize);
                    reader.readAsArrayBuffer(chunk);
                }
            }
        });
        if (!Channel.instance) {
            Channel.instance = this;
        }
        this.receivedFileBuffer = [];
        return Channel.instance;
    }
    addHandler(changeIndicators) {
        Channel.changeIndicators = changeIndicators;
    }
    init(props) {
        this.channel = props.channel;
        this.channel.onmessage = (ev) => {
            const message = new Response(ev.data).text();
            message.then((result) => {
                console.log(result, "RESULT");
                const message = JSON.parse(atob(result));
                if ("file_name" in message) {
                    this.downloadFile(message);
                }
                else {
                    this.sendingFile();
                }
            });
        };
    }
    downloadFile(message) {
        const uint8array = new Uint8Array(message.chunk);
        this.receivedFileBuffer.push(uint8array);
        console.info("received bit: ", message.chunk_number * this.chunkSize, "all bit: ", message.file_size);
        if (Channel.changeIndicators) {
            if (this.startDownload) {
                this.startDownload = false;
                const indicator = { fileName: message.file_name, indicator: new LoadedIndicator() };
                this.indicators.push(indicator);
                indicator.indicator.init(message.file_name, message.file_size);
            }
            else {
                this.indicators.forEach((indicator) => {
                    if (indicator.fileName === message.file_name) {
                        indicator.indicator.progress(message.chunk_number * this.chunkSize);
                    }
                });
                console.log(message.chunk_number, message.file_size);
            }
        }
        if ((message.chunk_number + 1) * this.chunkSize >= message.file_size) {
            const receivedBlob = new Blob(this.receivedFileBuffer);
            const url = URL.createObjectURL(receivedBlob);
            const link = document.createElement("a");
            link.href = url;
            link.download = message.file_name; // use the filename from the message
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.receivedFileBuffer = [];
            if (Channel.changeIndicators) {
                this.startDownload = true;
                Channel.changeIndicators({
                    fileName: message.file_id,
                    status: "finish"
                });
            }
        }
    }
    sendingFile() {
        if (this.currentChunk <= this.chunks) {
            if (Channel.changeIndicators) {
                if (this.currentChunk === this.firstChunkforOnMessageListener) {
                    const indicator = { fileName: this.file.name, indicator: new LoadedIndicator() };
                    this.indicators.push(indicator);
                    indicator.indicator.init(this.file.name, this.file.size);
                }
                else {
                    this.indicators.forEach((indicator) => {
                        if (indicator.fileName === this.file.name) {
                            indicator.indicator.progress(this.currentChunk * this.chunkSize);
                        }
                    });
                }
            }
            this.readFileInChunks();
        }
        else {
            console.info(`file: ${this.file.name} received`);
            if (Channel.changeIndicators) {
                this.indicators.forEach((indicator) => {
                    if (indicator.fileName === this.file.name) {
                        indicator.indicator.finish();
                    }
                    this.indicators = this.indicators.filter((indicator) => {
                        return indicator.fileName !== this.file.name;
                    });
                });
            }
        }
    }
    sendFile(file) {
        this.timestamp = Date.now();
        this.currentChunk = 0;
        this.file = file;
        this.id = Math.floor(Math.random() * 1000000 + 1);
        this.chunks = Math.ceil(this.file.size / this.chunkSize);
        this.readFileInChunks();
    }
    getChnannel() {
        return this.channel;
    }
}
export { Channel };
